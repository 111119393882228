import { types } from "../action";

// ** Initial State
const initialState = {
  dataFilterProp: {},
};

const FilterGeneralState = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DATA_FILTER_PROP:
      return { ...state, dataFilterProp: action.dataFilterProp };
    default:
      return { ...state };
  }
};
export default FilterGeneralState;
