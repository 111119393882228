
export const types = {

  SET_DATA_FILTER_PROP: "SET_DATA_FILTER_PROP",
};

export const setDataFilterProp = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_DATA_FILTER_PROP,
      dataFilterProp: data,
    });
  };
};
